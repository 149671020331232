<template>
	<div v-loading="pageloading">
		<div class="flexRow" style="justify-content: space-between">
			<el-card style="width:49.5%">
				<div class="titfont">开通企微旗舰版</div>
				<div class="bluebox" v-if="pagemsg.RuleDescription">
					{{pagemsg.RuleDescription}}
				</div>
				<div v-if="pagemsg.Cost>0">
					<div class="secfont" style="margin-top:20px">方法一：付费购买</div>
					<div class="bluebox" style="padding:0px;">
						<div class="flexRow center">
							<div class="flexCol leftbox" style="padding:10px">
								<div class="oneyear">1年</div>
								<div class="money">￥{{pagemsg.Cost}}</div>
							</div>
							<img src="../../assets/img/righttop.png" alt="">
						</div>
					</div>
					<div style="text-align:right">
						<el-button type="primary" style="width:180px;margin:20px 0px" @click="paynow">立即支付</el-button>
						<br />
						<el-link class="link" @click="serviceshow=true">
							<i style="color:#E6A23C" class="el-icon-warning-outline"></i>
							支付成功了却一直没开通?
						</el-link>
					</div>
				</div>
				<div v-if="pagemsg.IsShowRecommendModule">
					<div class="secfont" style="margin-top:30px">方法二：邀请好友助力</div>
					<div class="grayfont" style="margin-top:10px">
						邀请<span style="color:#f56c6c">{{pagemsg.RecommendCount}}</span>
						个好友使用企店，并成功授权绑定商城小程序，您即可免费获得功能1年的使用权限；免费使用权限仅可获得一次哦~
						<span style="color:#f56c6c" v-if="pagemsg.RecommendReward>0">
							（邀请的好友后续如果付费购买旗舰版，单笔支付您还可以获得{{pagemsg.RecommendReward}}元推荐奖励）
						</span>；
					</div>
					<div>
						<el-link class="link" :underline="false" type="primary" target="_blan"
							href="https://jusnn6k8al.feishu.cn/docs/doccnDDGHptpVb0YhyoWRZEPe6d">点击查看助力规则</el-link>
					</div>
					<div class="flexRow" style="justify-content: space-between;">
						<img :src="poster" style="width:350px" alt="">
						<div style="flex:1">
							<div class="flexRow">
								<el-input v-model="Inputlink" disabled></el-input>
								<el-button type="primary" v-clipboard:copy="Inputlink" v-clipboard:success="copysuc"
									v-clipboard:error="copyerr">复制邀请链接</el-button>
							</div>
							<el-button type="text" @click="downloadposter">下载海报图</el-button>
						</div>
					</div>
					<div class="bluebox">
						<div class="thifont">已邀请好友</div>
						<div class="grayfont">
							好友累计付费 <span style="color:#f56c6c">{{pagemsg.PayCount}}</span> 次,
							您当前累计获得奖励 <span style="color:#f56c6c">{{pagemsg.AwardMoney}}</span> 元
							<el-button type="text" @click="topup">奖励提现</el-button>
						</div>
						<el-table style="margin-top:10px" :data="pagemsg.RecommendMallList">
							<el-table-column prop="MallName" label="商家"></el-table-column>
							<el-table-column prop="IsAuth" label="小程序授权状态">
								<template slot-scope="scope">
									{{scope.row.IsAuth?'已授权':'未授权'}}
								</template>
							</el-table-column>
							<el-table-column prop="InviteTime" label="邀请时间"></el-table-column>
						</el-table>
					</div>
					<div style="text-align:right">
						<el-button type="primary" style="width:180px;margin:20px 0px" @click="openplate">开通企微旗舰版
						</el-button>
					</div>
				</div>
			</el-card>
			<el-card style="width:49.5%">
				<div class="titfont">功能详情</div>
				<div class="flexCol" style="margin-top:20px">
					<img v-for="(v,i) in pagemsg.FunctionDetailImgUrlComplete" :key="i" :src="v" alt="">
				</div>
			</el-card>
		</div>

		<el-dialog :visible.sync="payshow" v-loading="payloading" title="提示" width="400px">
			<div>使用支付宝扫描下方二维码，完成支付</div>
			<div class="flexRow" style="justify-content:center;">
				<!-- <img :src="paymsg.QrcodeUrl" alt=""> -->
				<vue-qr :text="paymsg.QrcodeUrl" :size="300" ref="Qrcode"></vue-qr>
			</div>
			<div style="text-align:right;margin-top:20px">
				<el-button @click="serviceshow = true">支付失败</el-button>
				<el-button type="primary" @click="paysuc">我已支付成功</el-button>
			</div>
		</el-dialog>

		<el-dialog title="提示" :visible.sync="serviceshow" width="400px">
			<div style="line-height:18px">
				授权或注册过程中，若遇到问题，使用微信扫描下方二维码，<span style="color:#E6A23C">添加专属客服</span>，一对一为您解答疑惑
			</div>
			<div style="width:100%;text-align:center">
				<img src="../../assets/img/20210719171848.jpg" style="width:150px;height:150px;margin-top:20px" alt="">
			</div>
			<div style="margin-top:20px;text-align:right">
				<el-button size="mini" @click="serviceshow = false">我知道了</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex"
	import {
		generateTitle
	} from '@/utils/i18n'
	import {
		paymentrulereceivepaymentrule,
		paymentruleapplypaymentrule,
		paymentrulebuypaymentruleResult
	} from "@/api/sv1.0.0"
	import vueQr from 'vue-qr'
	export default {
		computed: {
			...mapGetters(['pagemsg', 'pagename'])
		},
		components: {
			vueQr
		},
		data() {
			return {
				Inputlink: '',
				poster: '../../assets/img/righttop.png',
				pageloading: false,
				payshow: false,
				payloading: false,
				paymsg: {},
				serviceshow: false,
			}
		},
		methods: {
			paysuc() {
				this.getpayresult()
			},
			async getpayresult() {
				this.payloading = true
				try {
					let data = {
						PayOrderNo: this.paymsg.PayOrderNo,
					}
					console.log(data)
					let result = await paymentrulebuypaymentruleResult(data)
					if (result.IsSuccess) {
						// console.log(result)
						if (result.Result.IsPay) {
							this.$message.success('充值成功')
							this.$router.go(-1)
						} else {
							this.serviceshow = true
						}
					}
				} finally {
					this.payloading = false
				}
			},
			paynow() {
				// this.payshow = true
				this.getpaymsg()
			},
			async getpaymsg() {
				this.pageloading = true
				try {
					let data = {
						SystemPaymentRuleId: this.pagemsg.SystemPaymentRuleId
					}
					let result = await paymentruleapplypaymentrule(data)
					if (result.IsSuccess) {
						this.paymsg = result.Result
						this.payshow = true
						// console.log(result.Result)
					}
				} finally {
					this.pageloading = false
				}
			},
			generateTitle,
			copysuc() {
				this.$message.success('复制成功')
			},
			copyerr() {
				this.$message.error('复制失败')
			},
			downloadposter() {
				let a = document.createElement('a')
				let event = new MouseEvent('click')
				a.href = this.poster
				a.download = '邀请海报'
				a.dispatchEvent(event)

			},
			openplate() {
				if (this.pagemsg.RecommendMallList.length < this.pagemsg.RecommendCount) {
					this.$message.error('成功邀请N人才可获得免费使用权限哦~')
				} else {
					this.$confirm(`是否确认开通${this.pagemsg.RuleName}？[邀请好友助力免费获取]仅可使用1次`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						// console.log(this.pagename)
						this.tofreeopen()
					}).catch(() => {})
				}
			},
			async tofreeopen() {
				this.pageloading = true
				try {
					let data = {
						SystemPaymentRuleId: this.pagemsg.SystemPaymentRuleId
					}
					let result = await paymentrulereceivepaymentrule(data)
					if (result.IsSuccess) {
						this.$message.success('开通成功')

						this.$router.go(-1)
					}
				} finally {
					this.pageloading = false
				}
			},

			topup() {
				if (!this.pagemsg.IsShowRecommendModule) {
					this.$message.error('暂无【推荐奖励】功能权限')
				} else {
					this.$router.push({
						path: '/assets/myrecommended/index'
					})
				}
			},
		},
		created() {
			// var URL = window.location.href;
			// let h = URL.split(".")[0];
			// let domain = h.split("//")[1];
			// var secondParam = ''
			// if (process.env.NODE_ENV == 'development') {
			// 	// secondParam = 'bofenfen'//推手模式
			// 	secondParam = 'xcs' //推手模式
			// 	// secondParam = 'nyhzp'// secondParam ='dke'
			// 	// secondParam ='diankeyi3'
			// 	// secondParam = '1'//推手模式
			// 	// secondParam = 'zz'//免费版
			// 	// secondParam = 'lxy'//代理模式
			// } else {
			// 	secondParam = domain
			// }
			let secondParam = window.localStorage.getItem('mlmzMallKey')
			let head = ' http://admin.dkyapitest.cn' //测试
			// let head = '  http://admin.qidian.shop'//正式
			this.Inputlink = head + '/login/#/?mallId=' + secondParam


			this.$store.dispatch('getpaymentrulevalitemenu', {
				Sref: this.$route.query.Sref
			})
		}
	}
</script>

<style lang="less" scoped>
	.flexRow {
		display: flex;
		flex-direction: row;
	}

	.flexCol {
		display: flex;
		flex-direction: column;
	}

	.titfont {
		font-size: 18px;
		font-weight: bold;
	}

	.bluebox {
		padding: 10px;
		border: 1px solid #409eff;
		background: #fbfdfd;
		font-size: 14px;
		line-height: 1.5;
		margin-top: 20px;
	}

	.secfont {
		font-size: 16px;
		font-weight: bold;
	}

	.center {
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;

		/* height: 100px; */
		.leftbox {
			align-items: center;
			flex: 1;
			height: 120px;
			justify-content: space-evenly;

			.oneyear {
				width: 100px;
				height: 30px;
				color: white;
				font-size: 14px;
				font-weight: bold;
				background: #409EFF;
				display: flex;
				flex-direction: row;
				align-items: center;
				border-radius: 25px;
				justify-content: center;
			}

			.money {
				color: #409EFF;
				font-size: 26px;
				font-weight: bold;
			}
		}
	}

	.grayfont {
		font-size: 14px;
		color: #999999;
		line-height: 1.5;
	}

	.thifont {
		font-size: 14px;
		font-weight: bold;

	}
</style>
